import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import React, {memo} from "react";
import ParamsType from "@SearchResults/types/params/Params.type";
import IntermodalRouteWithDetailsType from "@Intermodal/types/IntermodalRouteWithDetails.type";
import RouteWithAddedPropsAndPricesType from "@Route/types/RouteWithAddedPropsAndPrices.type";
import SuggestedRouteType from "@Route/types/SuggestedRoute.type";
import RouteItemContainer from "@Route/components/Container/RouteItemContainer";
import RouteExpandedContainer from "@Route/components/Container/RouteExpandedContainer";
import usePrepareRoute from "@Route/hooks/usePrepareRoute";
import useActivateRoute from "@Route/hooks/useActivateRoute";
import RouteIconType from "@SearchResults/types/routeIcons/RouteIcon.type";
import ErrorBoundary from "@Errors/components/ErrorBoundary";
import useIsMobile from "@Hooks/selector/useIsMobile";
import RoutePassengerClassesContainer from "@Route/components/PassengerClasses/RoutePassengerClassesContainer";
import RouteWrapper from "@SearchResults/features/featuredRoutes/components/RouteWrapper";

interface RouteItemProps {
    route: IntermodalRouteWithDetailsType | RouteWithAddedPropsAndPricesType | SuggestedRouteType,
    params: ParamsType,
    isReturn?: boolean,
    routeIcons: Record<number, RouteIconType>,
    isActive: boolean,
    totalPassengers: number,
}

function RouteItem(props: RouteItemProps) {
    const {
        route,
        params,
        isReturn,
        routeIcons,
        isActive,
        totalPassengers,
    } = props;

    const {
        routeId,
        routeHiddenOnReturnTrip,
        panelClassName,
        routeExpandable,
    } = usePrepareRoute({
        route,
        isCurrentRouteSingle: !isReturn,
        isReturn: params.isReturn,
        isActive,
    })

    const {
        activateRoute,
        elRef,
    } = useActivateRoute({
        route,
        isCurrentRouteSingle: !isReturn,
        params,
        routeExpandable,
    })

    const isMobile = useIsMobile()
    // Do not show excluded route items unless user presses "load more"
    // routeHiddenOnReturnTrip Hides inactive routes (rest of single routes when single on return trip is expanded)
    if (routeHiddenOnReturnTrip) {
        return null
    }

    return (
        <ErrorBoundary>
            <RouteWrapper className={`panel gb--card ${panelClassName}`} isFeatured={route?.isFeatured}>
                <Accordion.Toggle
                    ref={elRef}
                    onClick={activateRoute}
                    as={Card.Header}
                    id={routeId}
                    eventKey={route.key}
                    className="gb--route gb--route-search panel-heading"
                >
                    <RouteItemContainer
                        isReturn={isReturn}
                        params={params}
                        route={route}
                    />
                </Accordion.Toggle>
                {routeExpandable && (
                    <Accordion.Collapse eventKey={route.key}>
                        <Card.Body className="gb--route-details">
                            {isActive ? (
                                <>
                                    {"passengerClasses" in route && route?.passengerClasses ? (
                                        <RoutePassengerClassesContainer
                                            isReturn={isReturn}
                                            passengerClasses={route?.passengerClasses}
                                            params={params}
                                            displayImages={!isMobile}
                                        />
                                    ) : (
                                        <RouteExpandedContainer
                                            routeIcons={routeIcons}
                                            route={route}
                                            params={params}
                                            isReturn={isReturn}
                                        />
                                    )}
                                </>
                            ) : (
                                <div style={{height: "400px"}}/>
                            )}
                        </Card.Body>
                    </Accordion.Collapse>
                )}
            </RouteWrapper>
        </ErrorBoundary>
    );
}

export default memo(RouteItem, (prevProps, nextProps) => (
    prevProps.isActive === nextProps.isActive
    && prevProps.totalPassengers === nextProps.totalPassengers
    && prevProps.isReturn === nextProps.isReturn
    && prevProps.route.prices.price === nextProps.route.prices.price
    && prevProps.params.returnDate === nextProps.params.returnDate
));