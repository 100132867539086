import ajaxHandler from "@Ajax/v2/ajaxHandler";
import {useAppSelector} from "@Hooks/selector/useAppSelector";
import {
    setCheckOrderFatalError, setCheckOrderUserMessages,
    setPassengerDataUpdating,
    setStripeClientSecret
} from "@CheckOrder/reducers/checkOrderSlice"
import {useAppDispatch} from "@Hooks/dispatch/useAppDispatch";
import useHandleError from "@Errors/hooks/useHandleError";

type StripePaymentParams = {
    privacyPolicyChecked: boolean,
    newsletterAccepted: boolean,
    tripReviewAccepted: boolean,
}

export default function useHandleStripePayment(params: StripePaymentParams) {
    const {setError} = useHandleError()
    const dispatch = useAppDispatch()
    const {
        newsletterAccepted,
        tripReviewAccepted,
        privacyPolicyChecked,
    } = params

    const {
        phoneCountryObject,
        phoneNumber,
        email,
        stripeEnabled,
        ticket: {
            ticketCode,
            priceInHrk,
            purchaseMaxTimestamp,
            ancillariesData,
        },
        tokens: {
            ipgFormToken,
        },
        isBuyingPremiumSupport,
        isBuyingSMSNotification,
    } = useAppSelector((state) => state.checkOrder)

    const convertToTinyInt = (value: boolean) => (value ? 1 : 0)

    const handleStripeFormSubmission = () => {
        const checkOrderData = {
            order_number: ticketCode,
            customer_email: email,
            // todo #hrk2eur #adomjanovic #llalic
            // @ante you should probably rename this to priceInEur as HRK is gone
            amount: priceInHrk,
            best_before: purchaseMaxTimestamp,
            customer_phone: phoneNumber,
            country: phoneCountryObject?.isoCode,
            proceed_to_ipg_payment_form: 1,
            stripe_enabled: convertToTinyInt(stripeEnabled),
            gbb_tos_and_privacy_policy: convertToTinyInt(privacyPolicyChecked),
            gbb_ticket_trip_review: convertToTinyInt(tripReviewAccepted),
            gbb_newsletter: convertToTinyInt(newsletterAccepted),
            ancillary_data: {
                gbb_is_buying_premium_support: isBuyingPremiumSupport,
                premiumSupportPrice: ancillariesData.premiumSupport.price,
                gbb_is_buying_sms_notification: isBuyingSMSNotification,
                smsNotificationPrice: ancillariesData.smsNotification.price,
            },
            _token: ipgFormToken,
        }

        const params = JSON.stringify(checkOrderData)

        return ajaxHandler({
            url: `/ajax/check-order-submit`,
            params,
            type: 'post',
            handle: "log",
        });
    }

    const handleSubmitResponse = (response) => {
        if (response.data.status === 'success') {
            dispatch(setStripeClientSecret(response.data.clientSecret));
        } else if (response.data.status === 'error') {
            dispatch(setCheckOrderFatalError(true));
        }
    }

    const handleStripePayment = () => {
        try {
            handleStripeFormSubmission().then((response) => {
                try {
                    handleSubmitResponse(response)
                    if (response.status !== 200) {
                        dispatch(setPassengerDataUpdating(false))
                        dispatch(setCheckOrderUserMessages([{error: response.data.error[0], additionalData: response.data.additionalInfo}]))
                    }
                } catch (error) {
                    dispatch(setPassengerDataUpdating(false))
                }
            });
        } catch (error) {
            // eslint-disable-next-line no-console
            console.log(error)
            setError(error)
        }
    }

    return {
        handleStripePayment,
    }
}