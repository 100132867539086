import {useAppSelector} from "@Hooks/selector/useAppSelector";

export default function useSaveCheckOrderSession() {
    const checkOrderData = useAppSelector((state) => state?.checkOrder)

    return () => {
        if (checkOrderData?.ticket?.ticketCode) {
            const sessionData = {
                email: checkOrderData.email,
                phoneNumber: checkOrderData.phoneNumber,
                phoneCountryObject: checkOrderData.phoneCountryObject,
                isBuyingPremiumSupport: checkOrderData.isBuyingPremiumSupport,
                isBuyingSMSNotification: checkOrderData.isBuyingSMSNotification,
                tripReviewAccepted: checkOrderData.tripReviewAccepted,
            }
            sessionStorage.setItem(checkOrderData.ticket.ticketCode, JSON.stringify(sessionData))
        }
    }
}