import React from 'react';
import RouteOtherDatesSuggestions from "@RouteSuggestions/components/RouteOtherDatesSuggestions";
import NoResults from "./NoResults";
import {isEmpty} from "@Array/isEmpty";
import {SearchResponseType} from "@SearchResults/types/SearchResponse.type";
import useSearchResultsEvent from "@Events/hooks/useSearchResultsEvent";

type NoResultsContainerProps = {
    searchQuery: SearchResponseType
}

function NoResultsContainer({searchQuery}: NoResultsContainerProps) {
    const {
        data: {
            result: {
                params,
                transfersData,
                getByFerryData,
            },
            suggestionData,
            urls,
        }
    } = searchQuery

    useSearchResultsEvent(searchQuery);

    return (
        <div className="no-results">
            {isEmpty(suggestionData?.dates) ? (
                <NoResults
                    urls={urls}
                    getByFerryData={getByFerryData}
                    params={params}
                    transfersData={transfersData}
                />
            ) : (
                <RouteOtherDatesSuggestions
                    params={params}
                    suggestionData={suggestionData}
                />
            )}
        </div>
    )
}

export default NoResultsContainer;