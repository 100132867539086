import SeatClassType from "@Route/types/travelInfo/SeatClass.type";
import IconMinivanOutline from "@Icons/IconMinivanOuline";
import React from "react";
import IconConnection from "@Icons/IconConnection";

type CombinedClassLabelProps = {
    seatClass: SeatClassType
}

function CombinedClassLabel({seatClass}: CombinedClassLabelProps) {
    return (
        <span className="gb--badge gb--badge-left gb--badge-blue">
            <IconConnection/>
            <span>
                {seatClass.className}
            </span>
        </span>
    );
}

export default CombinedClassLabel;