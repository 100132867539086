import React, {ReactNode} from "react";
import IconStarFull from "@Icons/IconStarFull";

type FeaturedRouteWrapperProps = {
    children: ReactNode
}

function FeaturedRouteWrapper(props: FeaturedRouteWrapperProps) {
    return (
        <div className={"featured-route-wrapper"}>
            <IconStarFull fill="#F1C644" stroke="navy"/>
            <span className="popular-departure-text">
                Popular departure
            </span>
            {props.children}
        </div>
    );
}

export default FeaturedRouteWrapper;