import React from 'react';

interface ModalFooterProps {
    handleClick: (any?) => any,
    buttonText: string,
    buttonClassName?: string,
    alternativeActionUrl?: string,
    alternativeActionText?: string,
    alternativeActionLinkTarget?: React.HTMLAttributeAnchorTarget,
    alternativeActionClick?: (any) => void
}

function ModalFooter(props: ModalFooterProps) {
    const {
        handleClick,
        buttonText,
        buttonClassName = "",
        alternativeActionUrl,
        alternativeActionText,
        alternativeActionLinkTarget = "_blank",
        alternativeActionClick,
    } = props;

    return (
        <div className="modal-footer gb-modal-footer">
            <div className="gb-modal-footer--actions">
                <button type="button" onClick={handleClick} className={`btn btn-default btn--confirm ${buttonClassName}`}>
                    {buttonText}
                </button>
            </div>
            {!!alternativeActionText && (
                <span className="gb-modal-footer--actions-alternative">
                    <a href={alternativeActionUrl} target={alternativeActionLinkTarget} rel="noreferrer" onClick={alternativeActionClick}>
                        {alternativeActionText}
                    </a>
                </span>
            )}
        </div>
    );
}

export default ModalFooter;
