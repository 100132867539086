import IntermodalRouteWithDetailsType from "@Intermodal/types/IntermodalRouteWithDetails.type";
import RouteWithAddedPropsAndPricesType from "@Route/types/RouteWithAddedPropsAndPrices.type";

/**
 * Sort routes by number of slots left
 */

type sortRoutesFunction = (
    a: RouteWithAddedPropsAndPricesType | IntermodalRouteWithDetailsType,
    b: RouteWithAddedPropsAndPricesType | IntermodalRouteWithDetailsType
) => number

export function sortByFeaturedRoutes(): sortRoutesFunction {
    return function sort(a, b) {
        const isFeatured = (route) => route?.isFeatured

        let comparison = 0;
        if (isFeatured(a) && !isFeatured(b)) {
            comparison = -1;
        } else if (!isFeatured(a) && isFeatured(b)) {
            comparison = 1;
        }

        return comparison
    };
}