import addRouteProperties from "@RoutesList/functions/addRouteProperties";
import CompanyType from "@SearchResults/types/company/Company.type";
import RouteType from "@Route/types/Route.type";
import {FeaturedRouteType} from "@SearchResults/features/featuredRoutes/types/FeaturedRoute.type";
import isFeaturedRoute from "@SearchResults/features/featuredRoutes/functions/isFeaturedRoute";
import {featuredRoutesDisplayLimit} from "@SearchResults/features/featuredRoutes/constants/featuredRoutesDisplayLimit";

export default function useAddRouteProperties(isReturn: boolean, companies: Record<string, CompanyType>, featuredRoutes: FeaturedRouteType[]) {
    const prepareRouteProperties = (routes: RouteType[]) => {
        return routes.map((route) => {
            let newRoute = null;
            if (route?.passengerClasses) {
                const mapped = route.passengerClasses.map((passengerClass) => addRouteProperties({
                    route: passengerClass,
                    isReturn,
                    companies,
                }))

                newRoute = {
                    ...route,
                    passengerClasses: mapped,
                }
            }

            if (routes.length > featuredRoutesDisplayLimit) {
                newRoute = {
                    ...newRoute ?? route,
                    isFeatured: isFeaturedRoute(route, featuredRoutes),
                }
            }

            return addRouteProperties({
                route: newRoute ?? route,
                isReturn,
                companies,
            })
        })
    }

    return {
        prepareRouteProperties,
    }
}