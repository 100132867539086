import Row from "@Containers/components/Row";
import React from "react";
import SeatClassType from "@Route/types/travelInfo/SeatClass.type";
import Column from "@Containers/components/Column";
import RouteIcons from "@Route/components/Amenities/RouteIcons";
import routeIconsHashMapResolver from "@Route/functions/routeIconsHashMapResolver";
import {useAppSelector} from "@Hooks/selector/useAppSelector";

type SeatClassProps = {
    seatClass: SeatClassType,
    displayImages?: boolean,
}

function SeatClass({seatClass, displayImages}: SeatClassProps) {
    return (
        <>
            <Column>
                <h4>{seatClass.className}</h4>
                <span>{seatClass.description}</span>
                <Column className="gb--route-amenities_icons">
                    {seatClass.amenities.map((amenity) => (
                        <Row key={amenity.id} style={{paddingTop: '1rem'}}>
                            <RouteIcons
                                tag={routeIconsHashMapResolver(amenity.id)}
                            />
                            <span>{amenity.label}</span>
                        </Row>
                    ))}
                </Column>
            </Column>
            {displayImages && (
                <Row className="gb--route-seat_classes">
                    {seatClass.images.map((image) => (
                        <img
                            key={image.url}
                            src={image.url}
                            alt={image.type}
                        />
                    ))}
                </Row>
            )}
        </>
    );
}

export default SeatClass;