import {ReactNode} from "react";
import Card from "react-bootstrap/Card";
import FeaturedRouteWrapper from "@SearchResults/features/featuredRoutes/components/FeaturedRouteWrapper";

type FeaturedRouteWrapperProps = {
    children: ReactNode,
    isFeatured: boolean,
    className?: string,
}

function RouteWrapper({children, className, isFeatured}: FeaturedRouteWrapperProps) {
    if (isFeatured) {
        return (
            <FeaturedRouteWrapper>
                <Card className={`panel gb--card ${className ?? ''}`}>
                    {children}
                </Card>
            </FeaturedRouteWrapper>
        )
    }
    return (
        <Card className={`panel gb--card ${className ?? ''}`}>
            {children}
        </Card>
    )
}

export default RouteWrapper;