import IntermodalRouteWithDetailsType from "@Intermodal/types/IntermodalRouteWithDetails.type";
import {sortByDeparture} from "@SearchResults/features/sorting/functions/sortByDeparture";
import {sortBySeatsLeft} from "@SearchResults/features/sorting/functions/sortBySeatsLeft";
import {sortByAvailability} from "@SearchResults/features/sorting/functions/sortByAvailablity";
import {sortByRating} from "@SearchResults/features/sorting/functions/sortByRating";
import {sortByPrice} from "@SearchResults/features/sorting/functions/sortByPrice";
import {sortByTravelTime} from "@SearchResults/features/sorting/functions/sortByTravelTime";
import RouteWithAddedPropsAndPricesType from "@Route/types/RouteWithAddedPropsAndPrices.type";
import {sortByFeaturedRoutes} from "@SearchResults/features/sorting/functions/sortByFeaturedRoutes";

/**
 * Sorts routes based on sortBy property
 */

interface SortRoutesAttributes {
    routes: (RouteWithAddedPropsAndPricesType | IntermodalRouteWithDetailsType)[],
    passengers: number,
    sortBy: 'departure' | 'rating' | 'travel_time' | 'price',
}

export default function sortRoutes(props: SortRoutesAttributes) {
    const {
        routes,
        passengers,
        sortBy,
    } = props

    const arrayClone = [...routes]

    if (sortBy === 'departure') {
        arrayClone.sort(sortByDeparture());
    }

    if (sortBy === 'rating') {
        arrayClone.sort(sortByRating());
    }

    if (sortBy === 'travel_time') {
        arrayClone.sort(sortByTravelTime());
    }

    if (sortBy === 'price') {
        arrayClone.sort(sortByPrice());
    }

    // Push featured routes to top of the list
    arrayClone.sort(sortByFeaturedRoutes());

    // Push unavailable and with too few seats to bottom of list
    arrayClone.sort(sortBySeatsLeft(passengers));
    arrayClone.sort(sortByAvailability());

    return arrayClone
}