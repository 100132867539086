import React from 'react'
import OvernightTravelLabel from "@Route/components/Labels/OvernightTravelLabel";
import MinivanLabel from "@Route/components/Labels/MinivanLabel";
import PrivateBusLabel from "@Route/components/Labels/PrivateBusLabel";
import SeatClassType from "@Route/types/travelInfo/SeatClass.type";
import seatClass from "@Route/components/SeatClass/SeatClass";
import CombinedClassLabel from "@Route/components/Labels/CombinedClassLabel";

interface RouteStatusLabelsProps {
    isMinivan?: boolean,
    isPrivateBus?: boolean,
    overnightTravel?: boolean,
    isPrivate?: boolean
    showOvernightTravel?: boolean
    seatClass?: SeatClassType
}

const RouteStatusLabels = (props: RouteStatusLabelsProps) => {
    const {
        isMinivan,
        isPrivateBus,
        overnightTravel,
        isPrivate,
        showOvernightTravel,
        seatClass,
    } = props

    if (isMinivan) {
        return (
            <MinivanLabel isPrivate={isPrivate}/>
        )
    }

    if (isPrivateBus) {
        return (
            <PrivateBusLabel/>
        )
    }

    if (seatClass?.isCombinedTransportTypeRoute) {
        return (
            <CombinedClassLabel
                seatClass={seatClass}
            />
        )
    }

    if (showOvernightTravel && overnightTravel) {
        return (
            <OvernightTravelLabel/>
        )
    }

    return null
}

export default RouteStatusLabels